import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {MachineList} from "../components/items/item-list"
import Catalog from "../services/catalog"

class BrowseMachinesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      machines: [],
    };
  }

  componentWillMount() {
    Catalog.getMachines().then(machines => this.setState({machines})).catch(console.error);
  }

  render() {
    const {machines} = this.state;

    return(
      <Layout navTab={1}>
        <SEO title="Machine Browser" />
        <MachineList items={machines}/>
      </Layout>
    );
  }
}

export default BrowseMachinesPage